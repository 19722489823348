import React from "react"
import { graphql } from "gatsby"
import { buildPageViewModel } from "../src/agility/agilityUtils"
import MainPage from "./MainPage"
import { useSiteContext } from "./components/common/SiteContext"
import slugify from "slugify"
import AgilityPageTemplate from './components/agility-pageTemplates/MainTemplate'
import truncate from "truncate-html"

//Our query to get the our page data and check for a dynamic page item (agilityItem)
export const query = graphql`
  query($pageID: Int!, $contentID: Int!, $languageCode: String!) {
    agilitypage(languageCode: { eq: $languageCode }, itemID: { eq: $pageID }) {
      pageJson
    }
    agilityitem(
      languageCode: { eq: $languageCode }
      itemID: { eq: $contentID }
    ) {
      itemJson
    }
  }
`
const AgilityPage = ({ pageContext, data, location }: any) => {
  const siteContext = useSiteContext()
  // build the page view model
  const viewModel = buildPageViewModel({ pageContext, data })

  // get our page template
  const templateName = viewModel.page.templateName.replace(/\s/g, "")


  // if dynamic page, use that description as meta description
  if (viewModel.dynamicPageItem?.seo?.metaDescription) {
    viewModel.page.seo.metaDescription =
      viewModel.dynamicPageItem.seo.metaDescription
  }

  var pageTitle = viewModel.page?.title ?? "";
  var pageDescription = truncate(`${viewModel.page.seo?.metaDescription ?? ""}`, 297);

  return (
    <MainPage
      seoProps={{
        title: pageTitle,
        description: pageDescription,
        keywords: viewModel.page.seo.metaKeywords,
        ogImage: viewModel.dynamicPageItem?.customFields?.image?.url,
        location: location,
      }}
      headerProps={{
        languageCode: viewModel.languageCode,
        isMultiLanguage: viewModel.isMultiLanguage,
        gradient: true,
        ...siteContext.headerProps,
      }}
      className={`content-page page-${slugify(viewModel.page.name)} page-id-${pageContext.pageID
        }`}
    >
      <AgilityPageTemplate {...viewModel} />
    </MainPage>
  )
}

export default AgilityPage
